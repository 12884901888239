import React, { FC, Fragment } from 'react';
import {
    Typography,
    Grid,
    Paper,
    Box,
    List,
    ListItem,
} from '@material-ui/core';
import Hero from '../Hero/Hero';

const GDPR: FC = () => {
    return (
        <Fragment>
            <Hero title="Tietosuojakäytäntö" />
            <section className="alignLeft">
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={12} md={8}>
                        <Box p={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid
                                        container={true}
                                        justifyContent="center"
                                    >
                                        <Grid item={true} xs={12} md={10}>
                                            <Typography variant="h4">
                                                Tietosuojakäytäntö K- Rengas ja
                                                Huolto Oy:ssä
                                            </Typography>
                                            <Typography variant="body1">
                                                Tässä tietosuojaselosteessa
                                                kerromme, minkälaisia
                                                henkilötietoja voimme kerätä
                                                sinusta käyttäessäsi
                                                palveluitamme tai ottaessasi
                                                yhteyttä Rengas Center Klaukkala
                                                Oy:n verkkosivuston kautta, sekä
                                                mihin tarkoituksiin voimme
                                                tällaisia tietoja käyttää.
                                            </Typography>
                                            <Typography variant="body1">
                                                Käyttämällä sivustoa
                                                www.klaukkalanrengas.fi,
                                                hyväksyt tietojesi käsittelyn
                                                tämän tietosuojaselosteen
                                                mukaisesti.
                                            </Typography>
                                            <Typography variant="body1">
                                                Voimme ajoittain muuttaa tätä
                                                tietosuojaselostetta
                                                julkaisemalla siitä uuden
                                                version verkossa, joten ole hyvä
                                                ja tarkista se säännöllisesti.
                                            </Typography>
                                            <Typography variant="body1">
                                                Verkkosivuston ja liitännäisten
                                                palvelujen jatkuva käyttö uuden
                                                version julkaisun jälkeen
                                                katsotaan muokattujen ehtojen
                                                hyväksynnäksi.
                                            </Typography>
                                            <List dense={true}>
                                                <ListItem>
                                                    <Typography variant="h5">
                                                        1. Tiedot rekisteristä
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Rekisterin nimi
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Rengas Center Klaukkala
                                                        Oy:n asiakasrekisteri
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="h5">
                                                        2. Rekisterinpitäjä
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Rengas Center Klaukkala
                                                        Oy
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Etunimi Sukunimi: Roman
                                                        Tuomisto
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Osoite: Klaukkalantie 74
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Paikkakunta: Klaukkala
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Y-tunnus: 2470070-6
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="h5">
                                                        3. Yhteyshenkilö
                                                        rekisteriasioissa:
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Etunimi Sukunimi: Roman
                                                        Tuomisto
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        <a href="mailto:klaukkalanrengas@gmail.fi">
                                                            klaukkalanrengas@gmail.fi
                                                        </a>
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                            <Typography variant="h4">
                                                Tietojen kerääminen
                                            </Typography>
                                            <List>
                                                <ListItem>
                                                    <Typography variant="h5">
                                                        Rekisteriin kerätään
                                                        seuraavat tiedot:
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        IP-osoitetieto tai muu
                                                        tunniste
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Ohjelmisto- ja
                                                        laitteistotiedot
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Evästeiden kautta
                                                        kerättävät tiedot
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Muut asiakkaan
                                                        suostumuksella
                                                        kerättävät tiedot
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Verkkopalvelustamme
                                                        keräämämme tiedot
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                            <Typography variant="body1">
                                                Tallennamme rekisteriimme
                                                tiedot, jotka olet itse
                                                toimittanut
                                                yhteydenottolomakkeella K-
                                                Rengas ja Huolto Oy:lle
                                                verkkosivujen{' '}
                                                <strong>
                                                    www.klaukkalanrengas.fi
                                                </strong>{' '}
                                                kautta tai{' '}
                                                <strong>
                                                    <a href="mailto:klaukkalanrengas@gmail.fi">
                                                        sähköpostilla
                                                    </a>
                                                </strong>
                                                .
                                            </Typography>
                                            <Typography variant="h4">
                                                Rekisterin käyttötarkoitus
                                            </Typography>
                                            <List>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Rekisteriä käytetään
                                                        henkilötietolakia
                                                        noudattaen
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Asiakassuhteen
                                                        hoitamiseen ja
                                                        kehittämiseen
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Palveluiden tuottamiseen
                                                        ja kehittämiseen
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Palvelusta ja tuotteista
                                                        tiedottamiseen ja
                                                        viestimiseen
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant="body1">
                                                        Palveluiden
                                                        tuottamisesta johtuvaan
                                                        laskuttamiseen
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                            <Typography variant="body1">
                                                Verkkopalvelussa annettujen
                                                tietojen perusteella pystymme
                                                myöntämään pääsyn niihin
                                                verkkopalvelun eri osiin ja
                                                sisältöihin, joita olet itse
                                                pyytänyt.
                                            </Typography>
                                            <Typography variant="body1">
                                                Henkilötietojen keräämisen ja
                                                käsittelyn kautta saatamme myös
                                                lähettää sinulle kohdennettuja
                                                markkinointi- tai
                                                asiakasviestejä.
                                            </Typography>
                                            <Typography variant="body1">
                                                Mikäli et kuitenkaan halua saada
                                                meiltä viestejä, voit milloin
                                                tahansa ilmoittaa asiasta K-
                                                Rengas ja Huolto Oy:lle.
                                            </Typography>
                                            <Typography variant="h4">
                                                Tietojen luovuttaminen
                                                kolmannelle osapuolelle
                                            </Typography>
                                            <Typography variant="body1">
                                                Rengas Center Klaukkala Oy ei
                                                myy, vaihda tai muutoin siirrä
                                                tunnistamisen mahdollistavia
                                                tietojasi ulkopuolisille
                                                osapuolille.
                                            </Typography>
                                            <Typography variant="body1">
                                                Tämä ei sisällä luotettuja
                                                kolmansia osapuolia, jotka
                                                auttavat meitä sivustomme
                                                ylläpidossa tai liiketoimintamme
                                                suorittamisessa, mutta myös
                                                näiden osapuolten on pidettävä
                                                tiedot luottamuksellisina.
                                            </Typography>
                                            <Typography variant="body1">
                                                Uskomme, että tietojen jakaminen
                                                on tarpeen, kun tavoitteena on
                                                tutkia, estää tai muutoin
                                                käsitellä laitonta toimintaa,
                                                petosepäilyjä, tilanteita, jotka
                                                mahdollisesti uhkaavat jonkin
                                                henkilön fyysistä
                                                turvallisuutta, käyttöehtojemme
                                                rikkomista sekä muissa lain
                                                vaatimissa tilanteissa.
                                                Ei-yksityisiä tietoja voidaan
                                                kuitenkin luovuttaa muille
                                                osapuolille markkinointiin,
                                                mainontaan tai muuhun käyttöön.
                                            </Typography>
                                            <Typography variant="h4">
                                                Rekisterin suojaus
                                            </Typography>
                                            <Typography variant="body1">
                                                Rekisteri on suojattu
                                                ulkopuolisten käytöltä.
                                                <br />
                                                Rekisterin käyttö on suojattu
                                                henkilökohtaisin
                                                käyttäjätunnuksin ja salasanoin.
                                                <br />
                                                Käyttäjiä sitoo
                                                salassapitovelvollisuus.
                                                <br />
                                                Rekisteriin pääsy on ainoastaan
                                                Rengas Center Klaukkala Oy:n
                                                työntekijöillä ja yrityksen
                                                verkkopalvelun tuottajan
                                                työntekijöillä, joiden tehtävät
                                                edellyttävät rekisterissä
                                                olevien tietojen käsittelyä.
                                                Työntekijöitä sitoo
                                                vaitiolovelvollisuus.
                                            </Typography>
                                            <Typography variant="h4">
                                                Evästeet
                                            </Typography>
                                            <Typography variant="body1">
                                                Evästeet (”cookies”) ovat pieniä
                                                alfanumeerisia tunnisteita,
                                                jotka tallentuvat kävijän
                                                tietokoneelle / päätelaitteelle,
                                                kun kävijä vierailee
                                                verkkosivulla. Verkkopalvelumme{' '}
                                                <strong>ei hyödynnä </strong>
                                                evästeitä.
                                            </Typography>{' '}
                                            <Typography variant="body1">
                                                Google kerää anonyymejä
                                                tilastotietoja sivuston
                                                kävijöistä tarkoituksena
                                                parantaa Palvelun laatua. Google
                                                Analytics kerää muun muassa
                                                tietoja siitä, millä sivuilla
                                                kävijä käy, kuinka kauan kävijä
                                                pysyy tietyllä sivulla, mitä
                                                kautta kävijä saapui sivulle ja
                                                mitä linkkejä kävijä klikkaa.
                                                Google saattaa luovuttaa tietoja
                                                kolmannella osapuolelle lain
                                                niin vaatiessa. Käyttämällä
                                                Palveluamme kävijä suostuu
                                                luovuttamaan yllämainittujen
                                                ehtojen mukaiset tiedot Googlen
                                                käyttöön. Mikäli et halua
                                                Palvelun saavan tietoja
                                                evästeiden avulla, voit kieltää
                                                evästeet verkkoselaimen
                                                asetuksista. Useimmat
                                                nykyaikaisista verkkoselaimista
                                                sallivat evästeiden käytön
                                                estämisen. Evästeiden käytön
                                                estäminen saattaa vaikuttaa
                                                Palvelun toimivuuteen emmekä voi
                                                taata Palvelun virheetöntä
                                                toimintaa ilman evästeiden
                                                käytön hyväksymistä.
                                            </Typography>
                                            <Typography variant="h4">
                                                Sosiaalisen median liitännäiset
                                            </Typography>
                                            <Typography variant="body1">
                                                Rengas Center Klaukkala Oy:n
                                                sivuilla voi olla linkkejä ja
                                                yhteyksiä kolmannen osapuolen
                                                verkkosivustoihin sekä niin
                                                sanottuja{' '}
                                                <strong>
                                                    sosiaalisen median
                                                    liitännäisiä
                                                </strong>{' '}
                                                (esimerkiksi Facebook,
                                                Instagram). Sosiaalisen median
                                                liitännäisten käyttäminen voi
                                                lähettää tunnistettavaa tietoa
                                                kyseisille sosiaalisen median
                                                palveluntarjoajille.
                                                Liitännäisen avatessasi saatat
                                                sallia kolmansien osapuolten
                                                internetsivujen evästeiden
                                                tallentumisen tietokoneellesi,
                                                jolloin näiden hallinnoijat
                                                voivat seurata sivun käyttöä,
                                                mikäli olet kirjautunut kyseisen
                                                palveluntarjoajan sosiaaliseen
                                                mediaan. <br />
                                                Näihin Klaukkalan Rengas Oy:n
                                                sivustoilla oleviin kolmannen
                                                osapuolen tarjoamiin palveluihin
                                                tai kolmannen osapuolen
                                                välittämiin sovelluksiin
                                                sovelletaan kolmannen osapuolen
                                                yksityisyys-, käyttö- ja muita
                                                ehtoja. Rengas Center Klaukkala
                                                Oy:llä ei ole määräysvaltaa
                                                tällaisiin kolmansien osapuolten
                                                internetsivuihin, eikä täten ole
                                                vastuussa mistään niillä
                                                julkaistusta materiaalista tai
                                                niiden käytöstä.
                                            </Typography>
                                            <Typography variant="h4">
                                                Kävijän oikeudet
                                            </Typography>
                                            <Typography variant="body1">
                                                Kävijällä on{' '}
                                                <strong>
                                                    henkilötietolain 26 §:n
                                                </strong>
                                                mukaisesti oikeus tarkastaa
                                                häntä koskevat tiedot ja vaatia
                                                virheellisen tiedon korjaamista
                                                rekisterissä.
                                            </Typography>
                                            <Typography variant="body1">
                                                Tarkastus- ja oikaisupyynnöt
                                                tulee lähettää kirjallisesti ja
                                                allekirjoitettuna tässä
                                                tietosuojaselosteessa
                                                määritellylle rekisteriasioita
                                                hoitavalle henkilölle.
                                            </Typography>
                                            <Typography variant="body1">
                                                Kävijällä on myös oikeus kieltää
                                                Rengas Center Klaukkala Oy:tä
                                                käsittelemästä sinua koskevia
                                                tietoja suoramarkkinointia,
                                                etämyyntiä tai
                                                mielipidetutkimusta varten
                                                ilmoittamalla siitä K- Rengas ja
                                                Huolto Oy:n rekisteriasioita
                                                hoitavalle henkilölle.
                                            </Typography>
                                            <Typography variant="h4">
                                                Muutokset tietosuojaselosteeseen
                                            </Typography>
                                            <Typography variant="body1">
                                                Päivitämme tietosuojaselosteemme
                                                muutokset viipymättä
                                                verkkopalveluumme.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
};

export default GDPR;
