import React, { useState, FormEvent, FC, ChangeEvent } from 'react';
import axios from 'axios';
import { IFormField } from '../../@types/types';
import { resetContactFormState } from '../../utils/reset';
import ResponseMessage from './ResponseMessage';
import Select from '../Select/Select';
import { contactFormFields } from './contactFormFields';
import Input from '../Input/Input';
import Button from '../Button/Button';

const ContactForm: FC = () => {
    const [contactForm, updateContactForm] = useState<any>(
        resetContactFormState(),
    );
    const [sending, setSending] = useState<boolean>(false);
    const [resMessage, updateResMessage] = useState<string>('');
    const [showResponse, updateShowResponse] = useState<boolean>(false);
    const handleChange = (event: any) => {
        updateContactForm({ [event.target.name]: event.target.value } as any);
    };
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        event.preventDefault();
        setSending(true);
        const formData = new FormData(event.target as any);
        const contact = {
            subject: formData.get('subject'),
            email: formData.get('email'),
            phonenumber: formData.get('phonenumber'),
            name: formData.get('name'),
            message: formData.get('message'),
        };
        axios
            .post('/api/contact', contact)
            .then((response: any) => {
                updateContactForm(resetContactFormState());
                updateResMessage(response.data.message);
                setSending(false);
                updateShowResponse(true);
            })
            .catch((error: any) => {
                console.log(error);
                updateResMessage(error.message);
            });
    };
    const handleClick = () => {
        updateShowResponse(false);
    };
    return (
        <>
            {showResponse ? (
                <ResponseMessage
                    message={resMessage}
                    handleClick={handleClick}
                />
            ) : (
                <form onSubmit={(event: FormEvent) => handleSubmit(event)}>
                    {contactFormFields.map((formField: IFormField) => {
                        return (
                            <>
                                {formField.type === 'select' ? (
                                    <Select
                                        errorText=""
                                        value={contactForm[formField.name]}
                                        handleChange={(
                                            event: ChangeEvent<HTMLSelectElement>,
                                        ) => handleChange(event)}
                                        handleInputChange={(
                                            event: ChangeEvent<HTMLInputElement>,
                                        ) => handleChange(event)}
                                        formField={formField}
                                        key={formField.id}
                                    />
                                ) : (
                                    <Input
                                        errorText=""
                                        value={contactForm[formField.name]}
                                        handleChange={(event: any) =>
                                            handleChange(event)
                                        }
                                        formField={formField}
                                        labelText={
                                            contactForm.subject ===
                                                'Tarjouspyyntö' &&
                                            formField.name === 'message'
                                                ? 'Renkaan koko'
                                                : undefined
                                        }
                                        key={formField.id}
                                    />
                                )}
                            </>
                        );
                    })}
                    <Button
                        loading={sending ? true : false}
                        type="submit"
                        buttonText={sending ? 'Lähetetään...' : 'Lähetä'}
                        size="lg"
                        disabled={sending ? true : false}
                    />
                </form>
            )}
        </>
    );
};

export default ContactForm;
