import React, { FC, Fragment } from 'react';
import '../../assets/scss/TyreHotel.scss';
import { Grid, Typography, Box, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Hero from '../Hero/Hero';
import AdStripe from '../AdStripe/AdStripe';
import { Phone } from '@material-ui/icons';

const TyreHotel: FC = () => {
    return (
        <Fragment>
            <Hero title="Rengashotelli" />
            <AdStripe
                href="https://venpoenergiaakterminaali.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1"
                adText="sisältää säilytyksen, allevaihdon ja pesun (Seuraavat kaudet 90€)"
                adHeader="Uusille rengashotellin asiakkaille ensimmäinen kausi vain "
                adPrice="70€"
                buttonText="Varaa"
            />
            <AdStripe
                href="https://venpoenergiaakterminaali.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1"
                adText="sisältää säilytyksen, allevaihdon ja pesun (Norm 90€/kausi)"
                adHeader="Talouden toisen auton sarjan säilytys aina "
                adPrice="70€/kausi"
                buttonText="Varaa"
            />
            <section>
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={12} md={8}>
                        <Box p={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid
                                        container={true}
                                        justifyContent="center"
                                    >
                                        <Grid item xs={10}>
                                            <Typography variant="h4">
                                                Sinun rengashotelli
                                            </Typography>
                                            <Typography variant="body1">
                                                Kaikki renkaat tarkistetaan ja
                                                vakuutetaan
                                            </Typography>
                                            <Typography variant="body1">
                                                Voit jättää renkaasi
                                                säilytykseen rengashotelliin ja
                                                tehdä renkaidenvaihdosta näin
                                                entistä mukavampaa. Säilytyksen
                                                lisäksi rengashotellin palveluun
                                                sisältyy{' '}
                                                <Link to="/palvelut">
                                                    kuntotarkastus ja
                                                    huuhtelupesu
                                                </Link>
                                                . Kerromme sinulle kun uusien
                                                renkaiden hankkiminen on
                                                ajankohtaista. Lisämaksusta voit
                                                tilata renkaidenvaihdon
                                                yhteyteen myös tasapainotuksen.
                                            </Typography>
                                            <Typography variant="h4">
                                                Rengashotellin ajanvaraus
                                            </Typography>
                                            <Typography variant="body1">
                                                Ajanvarauksen voit tehdä
                                                Klaukkalan renkaan
                                                verkkosivuilta osoitteesta{' '}
                                                <a
                                                    href="https://venpoenergiaakterminaali.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1"
                                                    target="__blank"
                                                >
                                                    www.klaukkalanrengas.fi/ajanvaraus
                                                </a>{' '}
                                                tai{' '}
                                                <a href="tel: 0300472380">
                                                    <Phone /> 0300 472 380
                                                </a>{' '}
                                                ark. 8.00-17.00 välisenä aikana.
                                            </Typography>
                                            <Typography variant="h4">
                                                Oikeat olosuhteet renkaiden
                                                säilyttämiseen
                                            </Typography>
                                            <Typography variant="body1">
                                                Renkaiden varastointi oikeissa
                                                olosuhteissa hidastaa niiden
                                                ikääntymistä, tästä puhutaan
                                                paljon joka paikassa, johtajamme
                                                mielestä se on kuitenkin vain
                                                markkinointia!
                                            </Typography>
                                            <Typography variant="body1">
                                                Renkaan elinikä harvoin ylittää
                                                sen suositeltua käyttö-ikää 5v.
                                            </Typography>
                                            <Typography variant="body1">
                                                Rengashotellin suurin etu
                                                sinulle piileekin siinä, ettei
                                                sinun selkä ja polvet joudu
                                                rasitukseen, etkä joudu kolealla
                                                ilmalla ja tuulisella
                                                parkkipaikalla vaihtamaan
                                                renkaita.
                                            </Typography>
                                            <Typography variant="body1">
                                                Antakaa se huoli meille ja
                                                vaihto onnistuu vartissa,{' '}
                                                <a
                                                    href="https://venpoenergiaakterminaali.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1"
                                                    target="__blank"
                                                >
                                                    ajanvarauksella
                                                </a>
                                                .
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
};

export default TyreHotel;
