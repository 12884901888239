import React, { Fragment, FC } from 'react';
import Hero from '../Hero/Hero';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Typography, Grid, Box, Paper } from '@material-ui/core';
import { Phone, Email } from '@material-ui/icons';

const Contact: FC = () => {
    return (
        <Fragment>
            <Hero title="Yhteystiedot" />
            <section>
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={12} md={10}>
                        <Box p={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid
                                        container={true}
                                        justifyContent="center"
                                    >
                                        <Grid item={true} xs={12} md={6}>
                                            <Typography variant="h4">
                                                Palvelemme
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Puhelimitse:</strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                <a href="tel: 0300472380">
                                                    <Phone /> 0300 472 380
                                                </a>{' '}
                                                <span>
                                                    Puhelun hinta: 0.42€/min +
                                                    matkapuhelin- tai
                                                    paikallisverkkomaksu
                                                </span>
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Sähköpostitse:</strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                <a href="mailto: klaukkalanrengas@gmail.com">
                                                    <Email />{' '}
                                                    klaukkalanrengas@gmail.com
                                                </a>
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Aukioloajat:</strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                ma - pe: 8 - 17
                                            </Typography>
                                            <Typography variant="body1">
                                                la: tilauksesta
                                            </Typography>
                                            <Typography variant="body1">
                                                su: suljettu
                                            </Typography>
                                            <Typography variant="body1">
                                                Vaihtokauden aikana palvelemme
                                                joustavasti
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            {
                                                <div
                                                    style={{
                                                        height: '50vh', // Important! Always set the
                                                        // container height explicitly
                                                        width: '100%',
                                                    }}
                                                >
                                                    <LoadScript googleMapsApiKey="AIzaSyDfK6JFSWQjCM1S7XqUVlHPoCoewjW5qN4">
                                                        <GoogleMap
                                                            mapContainerStyle={{
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                            center={{
                                                                lat: 60.389908,
                                                                lng: 24.740945,
                                                            }}
                                                            zoom={12}
                                                        >
                                                            {/* Child components, such as markers, info windows, etc. */}
                                                            <Marker
                                                                position={{
                                                                    lat: 60.389908,
                                                                    lng: 24.740945,
                                                                }}
                                                            />
                                                        </GoogleMap>
                                                    </LoadScript>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
};

export default Contact;
