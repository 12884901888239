import React, { FC, Fragment } from 'react';
import '../../assets/scss/Finance.scss';
import { Typography, Grid, Box, Paper, List, ListItem, CardContent, Card, Button } from '@material-ui/core';
import Hero from '../Hero/Hero';

const Finance: FC = () => {
    return (
        <Fragment>
            <Hero title="Rahoitus" />
            <section>
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={12} md={8}>
                        <Box p={2}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container={true} justifyContent="center">
                                        <Grid item={true} xs={12} md={10} className="alignLeft">
                                            <Typography variant="h4">Rengas Center Rahoitus</Typography>
                                            <Typography variant="body1">
                                                Hanki Rengas Center Rahoitus ja hoida hankintasi <strong>Vaivatta</strong> ja{' '}
                                                <strong>tilanteeseesi sopivalla tavalla</strong>.
                                            </Typography>
                                            <Typography variant="body1">
                                                Rengas Center Rahotuksen tarjoaa <strong>Resurs Bank</strong> ja{' '}
                                                <strong>Osuuspankki</strong>.
                                            </Typography>
                                            <Box my={2}>
                                                <Grid container={true} justifyContent="space-between" spacing={2}>
                                                    <Grid item={true} xs={12} md={12}>
                                                        <Card>
                                                            <img
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                src="https://images-pethotelli.s3.eu-west-1.amazonaws.com/rengas-center/rengas-center-resurs-bank-kampanja.png"
                                                                alt="Resurs Bank mainoskampanja"
                                                            />
                                                            <CardContent>
                                                                <Typography variant="h5" className="bgGreen textCenter">
                                                                    Resurs Bank Rahoitus
                                                                </Typography>
                                                                <Typography variant="h4">Maksa myöhemmin – omaan tahtiisi</Typography>
                                                                <Typography variant="body1">
                                                                    Ostaessasi RengasCenter -tilillä, valitset milloin ja miten haluat
                                                                    maksaa. Saat jopa 12 kuukautta korotonta maksuaikaa. Tilin hakeminen on
                                                                    helppoa ja nopeaa. Voit tehdä hakemuksen joko alla olevasta linkistä tai
                                                                    toimipaikassa paikan päällä. Saat laskun vasta ostoa seuraavana
                                                                    kuukautena ja voit silloin valita maksatko koko summan kerralla vai
                                                                    joustavasti erissä. Luotonantaja on Resurs Bank AB Suomen sivuliike.
                                                                </Typography>
                                                                <List>
                                                                    <ListItem>- Et maksa ostoksen teon yhteydessä mitään</ListItem>
                                                                    <ListItem>- 30–60 päivää ensimmäiseen eräpäivään</ListItem>
                                                                    <ListItem>
                                                                        - Voit jaksaa maksun osiin ja valita sinulle sopivan
                                                                        maksuvaihtoehdon
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        - Tili on jatkuva luotto, jota voit käyttää myös tuleviin ostoksiisi
                                                                    </ListItem>
                                                                </List>
                                                                <Button variant="outlined" color="primary">
                                                                    <a href="https://apponline.resurs.com/form/fi7500">Hae luottoa tästä</a>
                                                                </Button>
                                                                <Typography variant="body1">
                                                                    Saat luottopäätöksen heti ja saat luoton käyttöösi, kun olet
                                                                    allekirjoittanut luottosopimuksen sähköisesti. Tämän jälkeen voit
                                                                    käyttää tiliä maksutapana toimipisteessä näyttämällä vain voimassa
                                                                    olevan henkilötodistuksen kassalla.
                                                                </Typography>
                                                                <Typography variant="h5">Entäpä kustannukset?</Typography>
                                                                <Grid container={true}>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        MAKSUVAIHTOEHTO
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        {' '}
                                                                        VUOSIKORKO
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        {' '}
                                                                        KÄSITTELYMAKSU/KK
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        30–60 pv
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        0%
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        0 €
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        12 kk
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        0%
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        5,60 €
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        24 kk
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        12,90%
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        5,60 €
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        48 kk
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        15% + viitekorko
                                                                    </Grid>
                                                                    <Grid item={true} md={4} xs={4}>
                                                                        5,60 €
                                                                    </Grid>
                                                                </Grid>
                                                                <Typography variant="body2">
                                                                    * Viitekorolla tarkoitetaan kuluttajansuojalaissa viitattua korkolain 12
                                                                    §:n mukaista viitekorkoa, jonka Suomen Pankki julkistaa kahdesti
                                                                    vuodessa ja joka on voimassa 6 kuukautta kerrallaan. Sovellettava
                                                                    viitekorko voi olla enintään 5 % eli luoton vuosikorko voi olla enintään
                                                                    20 %, kuten kuluttajansuojalaissa säädetään.
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    <strong>Esimerkki </strong>on laskettu 12 kuukauden
                                                                    maksuvaihtoehdolle. Luottokulu 5,60 €/kk veloitetaan kuukausilaskulla.
                                                                    Tilirahoitus on jatkuva luotto, jonka minimiluottoraja on 2 000 euroa.
                                                                    Luoton muusta käytöstä voi seurata korkeampi tai alempi todellinen
                                                                    vuosikorko. Luotonantaja: Resurs Bank AB Suomen sivuliike.
                                                                </Typography>
                                                                <Typography variant="h5">Onko sinulla jo tili?</Typography>
                                                                <Typography variant="body1">
                                                                    Tarvittaessa voit myös itse korottaa jo aiemmin avattua tiliäsi oheisen
                                                                    hakemuslinkin kautta:{' '}
                                                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                                    <a
                                                                        href="https://apponline.resurs.com/form/fi7500/limitraise"
                                                                        target="_blank"
                                                                    >
                                                                        Linkki hakemukseen
                                                                    </a>
                                                                    .
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    Voit tarkistaa tilisi saldon kirjautumalla Resursin omille sivuille.
                                                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                                    <a href="https://www.resursbank.fi/kirjaudu-sisaan" target="_blank">
                                                                        Kirjaudu omille sivuille
                                                                    </a>
                                                                    .
                                                                </Typography>
                                                                <Typography variant="h5">Jäikö jokin mietityttämään?</Typography>
                                                                <Typography variant="body1">
                                                                    Ota yhteyttä Resursin asiakaspalveluun arkisin, puh.{' '}
                                                                    <a href="tel:096224308">09 622 4308</a>.
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item={true} xs={12} md={12}>
                                                        <Card>
                                                            <CardContent>
                                                                <Typography variant="h5" className="bgMidnightBlue textCenter">
                                                                    Osuuspankki
                                                                </Typography>
                                                                <List>
                                                                    <ListItem>
                                                                        <Typography variant="body1">Rahoitus 1 000–7 500 €</Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">
                                                                            -Kertaostos voi olla pienempi kuin haettu luotto.
                                                                        </Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">
                                                                            Kuukausi korotonta ja kulutonta maksuaikaa.
                                                                        </Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">Joustava takaisinmaksuaika.</Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">Korko OP-prime + 9,50%*.</Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">Laskutuspalkkio 3 €/kk.</Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">
                                                                            Voit maksaa koko luoton kerralla pois ilman lisäkuluja.
                                                                        </Typography>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <Typography variant="body1">
                                                                            Rahoitus kerryttää OP:n omistajaasiakkaille OP-bonuksia.
                                                                        </Typography>
                                                                    </ListItem>
                                                                </List>
                                                                <Typography variant="body1">
                                                                    *Rahoitus on kertaluotto, jonka todellinen vuosikorko 2000 € luotolle on
                                                                    13,16 %, kun luoton korko on OP prime + 9,5 % (9,75 % 09/19),
                                                                    perustamismaksu 0 €, laskutuspalkkio 3 €/kk. Arvioitu luoton
                                                                    kokonaiskustannus on 2595,77 €. Laskelma on tehty olettaen, että luotto
                                                                    on nostettu kokonaan, luoton korko sekä maksut ja palkkiot pysyvät
                                                                    samana koko luottoajan ja luotto maksetaan takaisin 50 €
                                                                    minimilyhennyksin kuukauden välein, jolloin luottoaika on 52 kk. Luoton
                                                                    myöntää OP Yrityspankki Oyj, Gebhardinaukio 1, 00510 Helsinki.
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    Laske itsellesi sopiva kuukausierä laskurilla ja täytä hakemus helposti.
                                                                </Typography>
                                                                <Button variant="outlined" color="primary">
                                                                    <a href="https://rengascenter.fi/palvelut/rahoitus/osuuspankki/">
                                                                        Täytä hakemus
                                                                    </a>
                                                                </Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
};

export default Finance;
