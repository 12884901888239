import { IOutletForm } from '../../@types/types';
import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { resetOutletForm } from '../../utils/reset';
import store from '../store';
import { AppActions } from './actions';
import {
    RESET_OUTLET_FORM,
    UPDATE_OUTLET_FORM,
} from './actionTypes/outletFormActionTypes';
import { addMessage } from './messageActions';

export function updateOutletForm(outletForm: IOutletForm): AppActions {
    return {
        type: UPDATE_OUTLET_FORM,
        outletForm,
    };
}

export function resetOutletFormAction(outletForm: IOutletForm): AppActions {
    return {
        type: RESET_OUTLET_FORM,
        outletForm,
    };
}

export async function createOutletItemOrder(outletForm: IOutletForm) {
    setHeader('post', null);
    apiCall('post', '/api/outlet/order', outletForm).then((response: any) => {
        store.dispatch(resetOutletFormAction(resetOutletForm()));
        store.dispatch(
            addMessage({
                text: response.message,
                bgColor: 'success',
                visible: true,
            }),
        );
    });
}
