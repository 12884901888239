import React, { FC } from 'react';
import { Grid, SnackbarContent, Icon, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import './Message.scss';

interface MessageProps {
    text: string;
    bgColor: string;
    onClose:
        | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | undefined;
}

const Message: FC<MessageProps> = ({ text, bgColor, onClose }) => {
    return (
        <Grid
            container={true}
            justifyContent="center"
            className="message-container"
        >
            <Grid item={true} xs={11} sm={8} md={6}>
                <SnackbarContent
                    className={bgColor}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className="message">
                            <Icon className="icon " />
                            {text}
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            onClick={onClose}
                        >
                            <CloseIcon className="icon" />
                        </IconButton>,
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default Message;
