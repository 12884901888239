import { IFormField, Subjects } from '../../@types/types';

const subjects: Subjects[] = ['Tarjouspyyntö', 'Palaute', 'Kehitysidea'];

export const contactFormFields: IFormField[] = [
    {
        type: 'select',
        name: 'subject',
        label: 'Aihe',
        required: true,
        id: 'subject',
        options: subjects,
    },
    {
        type: 'text',
        name: 'name',
        label: 'Etu- ja Sukunimi',
        required: true,
        id: 'name',
    },
    {
        type: 'tel',
        name: 'phonenumber',
        label: 'Puhelinnumero',
        required: false,
        id: 'phonenumber',
    },
    {
        type: 'email',
        name: 'email',
        label: 'Email',
        required: true,
        id: 'email',
    },
    {
        type: 'text',
        name: 'message',
        label: 'Viesti/ kysymys',
        required: true,
        id: 'message',
    },
];
