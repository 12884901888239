import { resetOutletForm } from '../../utils/reset';
import {
    OutletFormActionTypes,
    RESET_OUTLET_FORM,
    UPDATE_OUTLET_FORM,
} from '../actions/actionTypes/outletFormActionTypes';

const DEFAULT_STATE = resetOutletForm();

const outletFormReducer = (
    state = DEFAULT_STATE,
    action: OutletFormActionTypes,
) => {
    switch (action.type) {
        case UPDATE_OUTLET_FORM:
            return action.outletForm;
        case RESET_OUTLET_FORM:
            return action.outletForm;
        default:
            return state;
    }
};

export default outletFormReducer;
