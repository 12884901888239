import { ILogo } from '../../@types/types';

export const logos: ILogo[] = [
    {
        id: 1,
        src: '/logos/logo_fi.jpeg',
        alt: 'Logo FI',
    },
    {
        id: 2,
        src: '/logos/logo_se.jpeg',
        alt: 'Logo SE',
    },
    {
        id: 3,
        src: '/logos/logo_en.jpeg',
        alt: 'Logo EN',
    },
];
