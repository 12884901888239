import { combineReducers } from 'redux';
import messageReducer from './messageReducer';
import outletFormReducer from './outletformReducer';
import outletItemsReducer from './outletItemsReducer';

const rootReducer = combineReducers({
	message: messageReducer,
	outletForm: outletFormReducer,
	outletItems: outletItemsReducer
});

export default rootReducer;
