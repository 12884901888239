import React, { Component, Fragment } from 'react';
import '../../assets/scss/Services.scss';
import { Typography, Grid, Box, Paper } from '@material-ui/core';
import Hero from '../../components/Hero/Hero';
import { Phone } from '@material-ui/icons';

interface ServicesState {
    services: [];
}

class Services extends Component<{}, ServicesState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            services: [],
        };
    }

    public render() {
        return (
            <Fragment>
                <Hero title="Rengas palvelut" />
                <section>
                    <Grid container={true} justifyContent="center">
                        <Grid item={true} xs={12} md={8}>
                            <Box p={2}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid
                                            container={true}
                                            justifyContent="center"
                                        >
                                            <Grid item={true} xs={12} md={10}>
                                                <Typography variant="h4">
                                                    Rengas palvelumme
                                                </Typography>
                                                <Typography variant="body1">
                                                    Rengas Center Klaukkala on
                                                    täyden palvelun rengasliike,
                                                    rengaskauppa ja
                                                    rengashotelli.
                                                </Typography>
                                                <Typography variant="body1">
                                                    Tarkempaa lisätietoa
                                                    palveluista ja hinnoista saa{' '}
                                                    <a href="https://venpoenergiaakterminaali.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1">
                                                        ajanvarauksen
                                                    </a>{' '}
                                                    kautta, sekä{' '}
                                                    <strong>soittamalla</strong>{' '}
                                                    numerosta{' '}
                                                    <a href="tel: 0300472380">
                                                        <Phone /> 0300 472 380
                                                    </a>
                                                </Typography>
                                                <Box mt={3}>
                                                    <Grid
                                                        container={true}
                                                        justifyContent="center"
                                                    >
                                                        <Grid
                                                            item={true}
                                                            xs={12}
                                                            md={10}
                                                        >
                                                            <img
                                                                className="services"
                                                                src="/logos/rengastyot.jpeg"
                                                                alt="Rengas Center Klaukkala, rengastyöt"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </section>
            </Fragment>
        );
    }
}

export default Services;
