import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Phone, Room, Email } from '@material-ui/icons';
import LogoStripe from '../LogoStripe/LogoStripe';

const AppFooter: FC = () => {
    return (
        <>
            {window.location.pathname !== '/' && <LogoStripe />}
            <footer id="footer">
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={12} md={4}>
                        <Typography variant="h5">
                            Rengas Center Klaukkala
                        </Typography>
                        <Typography variant="body1">
                            <a href="tel: 0300472380">
                                <Phone /> 0300 472 380
                            </a>{' '}
                            <span>
                                Puhelun hinta: 0.42€/min + matkapuhelin- tai
                                paikallisverkkomaksu
                            </span>
                        </Typography>
                        <Typography variant="body1">
                            <a href="mailto: klaukkalanrengas@gmail.com">
                                <Email /> klaukkalanrengas@gmail.com
                            </a>
                        </Typography>
                        <Typography variant="body1">
                            <Link to="/yhteystiedot">
                                <Room /> Klaukkalantie 74 <br />
                                01800, Klaukkala
                            </Link>
                        </Typography>
                        <Typography variant="body1">
                            Y-tunnus: 2470070-6
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        <Typography variant="h5">Aukioloajat</Typography>
                        <Typography variant="body1">ma - pe: 8 - 17</Typography>
                        <Typography variant="body1">la: tilauksesta</Typography>
                        <Typography variant="body1">su: suljettu</Typography>
                        <Typography variant="body1">
                            Vaihtokauden aikana palvelemme joustavasti
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        <Typography variant="h5">Tietosuojakäytäntö</Typography>
                        <Typography variant="body1">
                            Tutustu{' '}
                            <Link to="/tietosuojakaytanto">
                                <strong>tietosuojakäytäntöömme</strong>
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </footer>
        </>
    );
};

export default AppFooter;
