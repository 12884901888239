import { Dispatch } from 'react';
import { IOutletItem } from '../../@types/types';
import { apiCall, setHeader } from '../../apiRequests/apiCall';
import { AppActions } from './actions';
import { SET_OUTLET_ITEMS } from './actionTypes/outletItemsActionTypes';

export function setOutletItems(outletItems: IOutletItem[]): AppActions {
    return {
        type: SET_OUTLET_ITEMS,
        outletItems,
    };
}

export const getOutletItems = (url: string = '/api/outlet') => {
    setHeader('get', '');
    return (dispatch: Dispatch<any>) => {
        return new Promise<void>((resolve, reject) => {
            return apiCall('get', url, {})
                .then(({ outletItems }: any) => {
                    dispatch(setOutletItems(outletItems));
                    resolve();
                })
                .catch((error: Error) => {
                    reject(); // indicate the API call failed
                });
        });
    };
};
