import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IButtonProps } from '../../@types/types';
import Icon from '../Icon/Icon';
import '../../assets/scss/Button.scss';

const Button: FC<IButtonProps> = ({
    type,
    size,
    buttonText,
    disabled,
    link,
    href,
    loading,
    handleClick,
}) => {
    return (
        <>
            {link ? (
                <Link to={href as string} className={`button button--${size}`}>
                    {buttonText}
                    <Icon iconName="arrowLeft" />
                </Link>
            ) : loading ? (
                <button
                    type={type}
                    disabled={disabled}
                    className={`button button--${size}`}
                >
                    {buttonText}
                    <Icon iconName="loading" />
                </button>
            ) : type === 'submit' ? (
                <button
                    type={type}
                    disabled={disabled}
                    className={`button button--${size}`}
                >
                    {buttonText}
                    <Icon iconName="arrowLeft" />
                </button>
            ) : (
                <button
                    type={type}
                    disabled={disabled}
                    className={`button button--${size}`}
                    onClick={handleClick}
                >
                    {buttonText}
                    <Icon iconName="arrowLeft" />
                </button>
            )}
        </>
    );
};

export default Button;
