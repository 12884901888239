import React, { FC } from 'react';
import { IResponseMessageProps } from '../../@types/types';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

const ResponseMessage: FC<IResponseMessageProps> = ({
    message,
    handleClick,
}) => {
    return (
        <div className="responseMessage">
            <div className="responseMessage--header">
                <span>
                    <Icon iconName="check" />
                </span>
                <h3>Kiitos yhteydenotostanne!</h3>
            </div>
            <p>{message}</p>
            <Button
                buttonText="Valmis"
                type="button"
                size="lg"
                handleClick={handleClick}
            />
        </div>
    );
};

export default ResponseMessage;
