import React, { ChangeEvent, FC, useState } from 'react';
import { IFormField } from '../../@types/types';

interface ISelectProps {
    formField: IFormField;
    handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    errorText: string;
}

const Select: FC<ISelectProps> = ({
    formField,
    value,
    handleChange,
    handleInputChange,
    errorText,
}) => {
    const [showOtherCountry, setShowOtherCountry] = useState<boolean>(
        () => false,
    );
    const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setShowOtherCountry(() =>
            event.target.value === 'World' ? true : false,
        );
        handleChange(event);
    };
    const { name, label, id, options, required } = formField;
    return (
        <div className={`mb-3 wrapper`}>
            <label htmlFor={id} className="form-label">
                {label}
            </label>
            <select
                name={name}
                id={id}
                required={required}
                className={`form-control wrapper__input`}
                onChange={changeHandler}
            >
                <option value={value}>{value}</option>
                {options &&
                    options.map((option: any) => {
                        return (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        );
                    })}
            </select>
            {showOtherCountry && (
                <input
                    type="text"
                    autoFocus={true}
                    name={name}
                    required={required}
                    value={value}
                    onInput={handleInputChange}
                    className={`form-control wrapper__input`}
                />
            )}
            {errorText && <p className="wrapper__errorText">{errorText}</p>}
        </div>
    );
};

export default Select;
