import { Box, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import React, { FC, useEffect } from 'react';
import OutletForm from '../../components/OutletForm/OutletForm';
import { getOutletItems } from '../../store/actions/outletItemsActions';

const Outlet: FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOutletItems());
        return () => {};
    }, [dispatch]);
    return (
        <>
            <section className="bgTyres">
                <Box ml={1}>
                    <Typography variant="h2" className="yellow">
                        Outlet
                    </Typography>
                </Box>
            </section>
            <section>
                <Grid container={true} justifyContent="flex-start">
                    <Grid item={true} md={12}>
                        <Box p={3} my={3}>
                            <Typography variant="h4">
                                Uutta Rengas Center Klaukkalassa!
                            </Typography>
                            <Typography variant="body1">
                                Rengas Center Klaukkala tarjoaa Outlet
                                myymälästä edullisia huippuluokan renkaita
                                asennustöineen edulliseen hintaan!
                            </Typography>
                            <Typography variant="body1">
                                Valitse sinulle sopivat renkaat ja lähetä
                                tilaus!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Box my={3} p={3}>
                    <Grid container={true} justifyContent="center">
                        <Grid item={true} md={7}>
                            <OutletForm />
                        </Grid>
                    </Grid>
                </Box>
            </section>
        </>
    );
};

export default Outlet;
