import {
    IContactForm,
    IFlashMessage,
    IOutletForm,
    IOutletFormItems,
    IOutletItem,
    Subjects,
} from '../@types/types';

export const resetContactFormState = (): IContactForm => {
    return {
        name: '',
        email: '',
        phonenumber: '',
        message: '',
        subject: '' as Subjects,
    };
};

export const resetOutletItems = (): IOutletItem => {
    return {
        size: '',
        manufacturer: 'Nokian-renkaat',
        carType: 'C-LL',
        category: 'Kesärenkaat',
        price: 0,
        description: '',
        _id: '',
        updatedAt: new Date(),
        createdAt: new Date(),
        booked: false,
    };
};

export const resetOutletForm = (): IOutletForm => {
    return {
        outletItemIds: [],
        email: '',
        phoneNumber: '',
        name: '',
    };
};

export const resetOutletFormItems = (): IOutletFormItems => {
    return {
        summerTyres: [],
        winterKitkaTyres: [],
        winterNastaTyres: [],
    };
};

export function resetMessage(): IFlashMessage {
    return {
        text: '',
        bgColor: '',
        visible: false,
    };
}
