import { IOutletForm } from '../../../@types/types';

export const UPDATE_OUTLET_FORM = 'UPDATE_OUTLET_FORM';
export const RESET_OUTLET_FORM = 'RESET_OUTLET_FORM';

export interface updateOutletForm {
    type: typeof UPDATE_OUTLET_FORM;
    outletForm: IOutletForm;
}

export interface resetOutletForm {
    type: typeof RESET_OUTLET_FORM;
    outletForm: IOutletForm;
}

export type OutletFormActionTypes = updateOutletForm | resetOutletForm;
