import { resetOutletItems } from '../../utils/reset';
import { SET_OUTLET_ITEMS, OutletItemsActionTypes } from '../actions/actionTypes/outletItemsActionTypes';

const DEFAULT_STATE = resetOutletItems();

const outletItemsReducer = (state = DEFAULT_STATE, action: OutletItemsActionTypes) => {
	switch (action.type) {
		case SET_OUTLET_ITEMS:
			return action.outletItems;
		default:
			return state;
	}
};

export default outletItemsReducer;
