import React, { FC } from 'react';
import {
    Grid,
    Card,
    CardMedia,
    Typography,
    CardContent,
    CardHeader,
} from '@material-ui/core';

interface ITyreCardProps {
    manufacturer: string;
    model: string;
    cover: string;
    car_type: string;
}

const TyreCard: FC<ITyreCardProps> = (props: ITyreCardProps) => {
    const { model, cover, car_type, manufacturer } = props;
    return (
        <Grid item={true} xs={6} md={3}>
            <Card>
                <CardHeader title={model} />
                <CardMedia image={cover} title={manufacturer + ' ' + model} />
                <CardContent>
                    <Typography variant="body1">{manufacturer}</Typography>
                    <Typography variant="body2">{car_type}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default TyreCard;
