import React, { FC, Fragment, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AppNavbar from './containers/AppNavbar/AppNavbar';
import AppFooter from './components/AppFooter/AppFooter';
import FrontPage from './containers/FrontPage/FrontPage';
import Services from './containers/Services/Services';
import Tyres from './containers/Tyres/Tyres';
import TyreHotel from './components/TyreHotel/TyreHotel';
import Finance from './components/Finance/Finance';
import GDPR from './components/GDPR/GDPR';
import Contact from './components/Contact/Contact';
import Offer from './containers/Offer/Offer';
import Outlet from './containers/Outlet/Outlet';
import { AppState } from './store/store';
import Message from './components/Message/Message';
import { resetMessage } from './utils/reset';
import { removeMessage } from './store/actions/messageActions';
import Ad from './components/Ad/Ad';

const App: FC = () => {
    const [activeItem, changeActiveLink] = useState<number>(() => 1);
    const { message } = useSelector((state: AppState) => state.message);
    const dispatch = useDispatch();
    const clearMessage = () => {
        dispatch(removeMessage(resetMessage()));
    };
    return (
        <Fragment>
            <Router>
                <AppNavbar
                    clickHandler={changeActiveLink}
                    activeItem={activeItem}
                />
                {/* <Ad /> */}
                <main>
                    <Switch>
                        <Route exact path="/" component={FrontPage} />
                        <Route path="/renkaat" component={Tyres} />
                        <Route path="/outlet" component={Outlet} />
                        <Route path="/rengashotelli" component={TyreHotel} />
                        <Route path="/palvelut" component={Services} />
                        <Route path="/rahoitus" component={Finance} />
                        <Route path="/tarjouspyynto" component={Offer} />
                        <Route path="/yhteystiedot" component={Contact} />
                        <Route path="/tietosuojakaytanto" component={GDPR} />
                        <Redirect to="/" />
                    </Switch>
                </main>
                {message.visible && (
                    <Message
                        text={message.text}
                        bgColor={message.bgColor}
                        onClose={() => clearMessage()}
                    />
                )}
                <AppFooter />
            </Router>
        </Fragment>
    );
};

export default App;
