const navbarLinks = [
    {
        href: '/',
        text: 'Etusivu',
        id: 1,
    },
    {
        href: '/renkaat?category=Kesärenkaat',
        text: 'Renkaat',
        id: 2,
    },
    {
        id: 3,
        text: 'Outlet',
        href: '/outlet',
    },
    {
        href: '/rengashotelli',
        text: 'Rengashotelli',
        id: 4,
    },
    {
        href: '/palvelut',
        text: 'Palvelut',
        id: 5,
    },
    {
        href: '/rahoitus',
        text: 'Rahoitus',
        id: 6,
    },
    {
        href: '/tarjouspyynto',
        text: 'Tarjouspyyntö',
        id: 7,
    },
    { href: '/yhteystiedot', text: 'Yhteystiedot', id: 8 },
];

export default navbarLinks;
