import { Box, Card, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import ContactForm from '../../components/Contact/ContactForm';
import Hero from '../../components/Hero/Hero';

const Offer: FC = () => {
    return (
        <>
            <section>
                <Hero title="Tarjouspyyntö" />
            </section>
            <section>
                <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={10} md={6}>
                        <Box mt={2}></Box>
                        <Card>
                            <Typography variant="h2">
                                Jätä tarjouspyyntö
                            </Typography>
                            <Box p={2}>
                                <ContactForm />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </>
    );
};

export default Offer;
