import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { ILogo } from '../../@types/types';
import { logos } from './logos';
import '../../assets/scss/LogoStripe.scss';

const LogoStripe: FC = () => {
    return (
        <section className="logoStripe">
            <Grid container={true} justifyContent="space-around">
                {logos.map((logo: ILogo) => {
                    return (
                        <Grid key={logo.id} item={true} md={4}>
                            <img src={logo.src} alt={logo.alt} />
                        </Grid>
                    );
                })}
            </Grid>
        </section>
    );
};

export default LogoStripe;
