import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

export function setHeader(contentType: string, token: string | null) {
    axios.defaults.headers.post['Content-Type'] = contentType;
    if (token) {
        // tslint:disable-next-line
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        // tslint:disable-next-line
        delete axios.defaults.headers.common['Authorization'];
    }
}

export const apiCall = (
    method: AxiosRequestConfig['method'],
    path: AxiosRequestConfig['url'],
    data: AxiosRequestConfig['data'],
) => {
    return new Promise((resolve: (value?: unknown) => void, reject: any) => {
        const config: AxiosRequestConfig = {
            url: path,
            method,
            data,
        };
        return axios(config)
            .then((res: AxiosResponse<any>) => {
                console.log(res.data);
                return resolve(res.data);
            })
            .catch((error: any) => {
                return reject(error.response.data);
            });
    });
};
