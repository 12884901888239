import React, { FC, Component } from 'react';
import '../../assets/scss/AppNavbar.scss';
import navbarLinks from './navbarLinkData';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EventAvailable } from '@material-ui/icons';

interface IAppNavbarProps {
    activeItem: number;
    clickHandler: (id: number) => void;
}

interface IAppNavbarState {
    anchorEl: null | HTMLElement;
}

interface NavLinkProps extends IAppNavbarProps {
    href: string;
    text: string;
    id: number;
}

const NavbarLink: FC<NavLinkProps> = (props) => {
    const { href, text, id, activeItem, clickHandler } = props;
    return (
        <Typography variant="h5">
            <Link
                onClick={() => clickHandler(id)}
                className={id === activeItem ? 'active' : ''}
                to={href}
            >
                {text}
            </Link>
        </Typography>
    );
};
class AppNavbar extends Component<IAppNavbarProps, IAppNavbarState> {
    constructor(props: IAppNavbarProps) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };
    render() {
        const { activeItem, clickHandler } = this.props;
        const { anchorEl } = this.state;
        return (
            <AppBar id="navbar">
                <Toolbar className="desktop">
                    <Link to="/">
                        <img
                            className="logo"
                            src="/logos/logo_main_withname.png"
                            alt="Rengas Center Klaukkala, klaukkalan rengas Oy"
                        />
                    </Link>
                    {navbarLinks.map((link) => {
                        return (
                            <NavbarLink
                                key={link.id}
                                clickHandler={clickHandler}
                                activeItem={activeItem}
                                id={link.id}
                                text={link.text}
                                href={link.href}
                            />
                        );
                    })}
                    <Typography variant="h5">
                        <a
                            href="https://rengascenterklaukkala.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1"
                            target="__blank"
                        >
                            Ajanvaraus
                        </a>
                    </Typography>
                </Toolbar>
                <Toolbar className="mobile">
                    <Link to="/">
                        <img
                            className="logo"
                            src="/logos/logo_main_withname.png"
                            alt="Rengas Center Klaukkala"
                        />
                    </Link>
                    <Typography variant="h5">
                        <a
                            href="https://rengascenterklaukkala.compilator.com/public/cms?Id=1&CompanyId=a01dab7a-734a-42c4-a658-fdf796dc0d83&branchId=1"
                            target="__blank"
                        >
                            <EventAvailable />
                        </a>
                    </Typography>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        className="menuButton"
                    >
                        <Typography variant="h5">Menu</Typography>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        {navbarLinks.map((link) => {
                            return (
                                <MenuItem
                                    key={link.id}
                                    onClick={this.handleClose}
                                >
                                    <Link to={link.href}>{link.text}</Link>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    }
}

export default AppNavbar;
