import { IOutletItem } from "../../../@types/types";

export const SET_OUTLET_ITEMS = 'SET_OUTLET_ITEM';

export interface SetOutletItems {
    type: typeof SET_OUTLET_ITEMS;
    outletItems: IOutletItem[];
}

export type OutletItemsActionTypes = SetOutletItems;
