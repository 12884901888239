import React, { FC } from 'react';
import '../../assets/scss/Hero.scss';
import { Typography } from '@material-ui/core';

interface IHeroProps {
    title: string;
}

const Hero: FC<IHeroProps> = (props: IHeroProps) => {
    const { title } = props;
    return (
        <section className="hero">
            <Typography variant="h2">{title}</Typography>
        </section>
    );
};

export default Hero;
