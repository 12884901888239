import React, { Component, Fragment } from 'react';
import '../../assets/scss/Tyres.scss';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { apiCall, setHeader } from '../../apiRequests/apiCall';
import TyreCard from './TyreCard';
import TyreHeader from './TyreHeader';
import { RouteComponentProps } from 'react-router-dom';
import AdStripe from '../../components/AdStripe/AdStripe';
import { ITyre } from '../../@types/types';

interface ITyresStateProps {
    category: string;
}
interface ITyresState {
    carTyres: ITyre[];
    cllTyres: ITyre[];
    suvTyres: ITyre[];
}

type TyresProps = RouteComponentProps & ITyresStateProps;

class Tyres extends Component<TyresProps, ITyresState> {
    constructor(props: TyresProps) {
        super(props);
        this.state = {
            carTyres: [],
            cllTyres: [],
            suvTyres: [],
        };
    }

    public componentDidMount() {
        setHeader('get', '');
        apiCall('get', '/api/tyres' + this.props.location.search, {}).then(
            ({ carTyres, cllTyres, suvTyres }: any) => {
                this.setState({
                    carTyres: carTyres,
                    cllTyres: cllTyres,
                    suvTyres: suvTyres,
                });
            },
        );
    }

    public getTyres(category: string) {
        this.props.location.search = '?category=' + category;
        setHeader('get', '');
        apiCall('get', '/api/tyres?category=' + category, {}).then(
            ({ carTyres, cllTyres, suvTyres }: any) => {
                this.setState({
                    carTyres: carTyres,
                    cllTyres: cllTyres,
                    suvTyres: suvTyres,
                });
            },
        );
    }

    public render() {
        const { carTyres, suvTyres, cllTyres } = this.state;
        return (
            <Fragment>
                <section className="bgTyres">
                    <Box ml={1}>
                        <Typography variant="h2" className="yellow">
                            Renkaat
                        </Typography>
                    </Box>
                </section>
                <section>
                    <Grid container={true} justifyContent="center">
                        <Grid item xs={12} md={10}>
                            <Box p={2}>
                                <Typography variant="h4">
                                    Renkaat -{' '}
                                    {this.props.location.search
                                        .split('-')
                                        .join(' ')
                                        .split('?')
                                        .join('')
                                        .split('=')
                                        .join('')
                                        .split('category')
                                        .join('')}
                                </Typography>
                                <Button
                                    className={
                                        this.props.location.search.includes(
                                            'Kesärenkaat',
                                        )
                                            ? 'active'
                                            : ''
                                    }
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={() => this.getTyres('Kesärenkaat')}
                                >
                                    Kesärenkaat
                                </Button>
                                <Button
                                    className={
                                        this.props.location.search.includes(
                                            'Talvirenkaat-Kitka',
                                        )
                                            ? 'active'
                                            : ''
                                    }
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={() =>
                                        this.getTyres('Talvirenkaat-Kitka')
                                    }
                                >
                                    Talvirenkaat Kitka
                                </Button>
                                <Button
                                    className={
                                        this.props.location.search.includes(
                                            'Talvirenkaat-Nasta',
                                        )
                                            ? 'active'
                                            : ''
                                    }
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={() =>
                                        this.getTyres('Talvirenkaat-Nasta')
                                    }
                                >
                                    Talvirenkaat Nasta
                                </Button>
                                <TyreHeader headerText="Henkilöauto Renkaat" />
                                <Grid
                                    container={true}
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    {!!carTyres.length &&
                                        carTyres.map((tyre: ITyre) => {
                                            return (
                                                <TyreCard
                                                    key={tyre._id}
                                                    cover={tyre.cover}
                                                    manufacturer={
                                                        tyre.manufacturer
                                                    }
                                                    car_type={tyre.car_type}
                                                    model={tyre.model}
                                                />
                                            );
                                        })}
                                </Grid>
                                <TyreHeader headerText="SUV Renkaat" />
                                <Grid
                                    container={true}
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    {!!suvTyres.length &&
                                        suvTyres.map((tyre: ITyre) => {
                                            return (
                                                <TyreCard
                                                    key={tyre._id}
                                                    cover={tyre.cover}
                                                    manufacturer={
                                                        tyre.manufacturer
                                                    }
                                                    car_type={tyre.car_type}
                                                    model={tyre.model}
                                                />
                                            );
                                        })}
                                </Grid>
                                <TyreHeader headerText="C-LL Renkaat" />
                                <Grid
                                    container={true}
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    {!!cllTyres.length &&
                                        cllTyres.map((tyre: ITyre) => {
                                            return (
                                                <TyreCard
                                                    cover={tyre.cover}
                                                    manufacturer={
                                                        tyre.manufacturer
                                                    }
                                                    key={tyre._id}
                                                    car_type={tyre.car_type}
                                                    model={tyre.model}
                                                />
                                            );
                                        })}
                                </Grid>
                                <AdStripe
                                    href="tel: 0300472380"
                                    buttonText="Soita"
                                    adHeader="Tilauksesta ovat kaikki muutkin
                                        rengas merkit ja mallit
                                        saatavilla."
                                    adText="Lisätietoa 0300 472 380 arkisin 08:00 - 17:00"
                                    adPrice=""
                                    offer={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </section>
            </Fragment>
        );
    }
}

export default Tyres;
