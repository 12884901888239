import { Box, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { IFormField, IOutletFormItems, IOutletItem } from '../../@types/types';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { outletFormFields } from './outletFormFields';
import { createOutletItemOrder, updateOutletForm } from '../../store/actions/outletFormActions';
import { AppState } from '../../store/store';
import { resetOutletFormItems } from '../../utils/reset';

const OutletForm: FC = () => {
    const dispatch = useDispatch();
    const outletForm: any = useSelector((state: AppState) => state.outletForm);
    const outletItems: IOutletItem[] = useSelector((state: AppState) => state.outletItems);
    const [outletFormItems, setOutletFormItems] = useState<IOutletFormItems>(() => resetOutletFormItems());
    function updateIdsArray(id: string): Promise<string[]> {
        return outletForm.outletItemIds.includes(id)
            ? outletForm.outletItemIds.filter((itemId: string) => {
                  return itemId !== id;
              })
            : [...outletForm.outletItemIds, id];
    }
    useEffect(() => {
        if (
            outletItems.length &&
            !outletFormItems.summerTyres.length &&
            !outletFormItems.winterKitkaTyres.length &&
            !outletFormItems.winterNastaTyres.length
        ) {
            setOutletFormItems({
                summerTyres: outletItems.filter((outletItem: IOutletItem) => outletItem.category === 'Kesärenkaat'),
                winterKitkaTyres: outletItems.filter((outletItem: IOutletItem) => outletItem.category === 'Talvirenkaat-Kitka'),
                winterNastaTyres: outletItems.filter((outletItem: IOutletItem) => outletItem.category === 'Talvirenkaat-Nasta'),
            });
        }
    }, [setOutletFormItems, outletFormItems, outletItems]);
    /** Handle outletForm state change */
    const handleChange = (event: any, id?: string) => {
        if (id) {
            dispatch(
                updateOutletForm({
                    ...outletForm,
                    outletItemIds: updateIdsArray(id),
                })
            );
        } else {
            dispatch(
                updateOutletForm({
                    ...outletForm,
                    [event.target.name]: event.target.value,
                })
            );
        }
    };
    /** Handle outletForm submit */
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        createOutletItemOrder(outletForm);
    };
    return (
        <section>
            <form onSubmit={handleSubmit}>
                <Box p={3}>
                    <Grid container={true}>
                        <Grid item={true} md={12}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Kesärenkaat" />
                                </ListItem>
                                {outletFormItems.summerTyres.length ? (
                                    outletFormItems.summerTyres
                                        .filter((outletItem: IOutletItem) => {
                                            return !outletItem.booked;
                                        })
                                        .map((outletItem: IOutletItem) => {
                                            return (
                                                <ListItem key={outletItem._id}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={outletForm.outletItemIds.includes(outletItem._id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                'aria-labelledby': outletItem._id,
                                                            }}
                                                            onChange={(event: any) => handleChange(event, outletItem._id)}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`${outletItem.size} ${outletItem.manufacturer} ${outletItem.price}€`}
                                                        secondary={`
                                                            4kpl ${outletItem.description}
                                                        `}
                                                    ></ListItemText>
                                                </ListItem>
                                            );
                                        })
                                ) : (
                                    <ListItem>
                                        <ListItemText secondary="Pian tulossa uusia tajouksia kesärenkaisiin." />
                                    </ListItem>
                                )}
                                <ListItem>
                                    <ListItemText primary="Talvirenkaat-Kitka" />
                                </ListItem>
                                {outletFormItems.winterKitkaTyres.length ? (
                                    outletFormItems.winterKitkaTyres
                                        .filter((outletItem: IOutletItem) => {
                                            return !outletItem.booked;
                                        })
                                        .map((outletItem: IOutletItem) => {
                                            return (
                                                <ListItem key={outletItem._id}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={outletForm.outletItemIds.includes(outletItem._id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                'aria-labelledby': outletItem._id,
                                                            }}
                                                            onChange={(event: any) => handleChange(event, outletItem._id)}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`${outletItem.size} ${outletItem.manufacturer} ${outletItem.price}€`}
                                                        secondary={`
                                                            4kpl ${outletItem.description}
                                                        `}
                                                    ></ListItemText>
                                                </ListItem>
                                            );
                                        })
                                ) : (
                                    <ListItem>
                                        <ListItemText secondary="Pian tulossa uusia tajouksia kitka talvirenkaisiin." />
                                    </ListItem>
                                )}
                                <ListItem>
                                    <ListItemText primary="Talvirenkaat-Nasta" />
                                </ListItem>
                                {outletFormItems.winterNastaTyres.length ? (
                                    outletFormItems.winterNastaTyres
                                        .filter((outletItem: IOutletItem) => {
                                            return !outletItem.booked;
                                        })
                                        .map((outletItem: IOutletItem) => {
                                            return (
                                                <ListItem key={outletItem._id}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={outletForm.outletItemIds.includes(outletItem._id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                'aria-labelledby': outletItem._id,
                                                            }}
                                                            onChange={(event: any) => handleChange(event, outletItem._id)}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`${outletItem.size} ${outletItem.manufacturer} ${outletItem.price}€`}
                                                        secondary={`
                                                            4kpl ${outletItem.description}
                                                        `}
                                                    ></ListItemText>
                                                </ListItem>
                                            );
                                        })
                                ) : (
                                    <ListItem>
                                        <ListItemText secondary="Pian tulossa uusia tajouksia nasta talvirenkaisiin." />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container={true}>
                        {outletFormFields.map((formField: IFormField) => {
                            return (
                                <Grid item={true} md={12} key={formField.id}>
                                    <Input formField={formField} handleChange={handleChange} value={outletForm[formField.name]} />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid container={true}>
                        <Grid item={true} md={12}>
                            <Button type="submit" size="md" buttonText="Lähetä varaus"></Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </section>
    );
};

export default OutletForm;
