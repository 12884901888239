export const offerCards = [
    {
        url: '/renkaat?category=Kesärenkaat',
        image: '/icons/kesarenkaat_22.png',
        title: 'Kesärenkaat',
        id: 1,
    },
    {
        url: '/renkaat?category=Talvirenkaat-Kitka',
        image: '/icons/talvirenkaat_23.gif',
        title: 'Talvirenkaat Kitka',
        id: 2,
    },
    {
        url: '/renkaat?category=Talvirenkaat-Nasta',
        image: '/icons/talvirenkaat_23.gif',
        title: 'Talvirenkaat Nasta',
        id: 3,
    },
    {
        url: '/rengashotelli',
        image: '/icons/rengashotelli_24.gif',
        title: 'Rengashotelli',
        id: 4,
    },
    {
        url: '/rahoitus',
        image: '/icons/rahoitus_25.png',
        title: 'Rahoitus',
        id: 5,
    },
];
