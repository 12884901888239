import { IFormField } from '../../@types/types';

export const outletFormFields: IFormField[] = [
    {
        type: 'text',
        name: 'name',
        label: 'Etu- ja sukunimi',
        id: 'name',
        required: true,
    },
    {
        type: 'tel',
        name: 'phoneNumber',
        label: 'Puhelinnumero',
        id: 'phoneNumber',
        required: true,
    },
    {
        type: 'email',
        name: 'email',
        label: 'Email',
        id: 'email',
        required: true,
    },
];
