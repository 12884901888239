import React, { Component, Fragment } from 'react';
import '../../assets/scss/FrontPage.scss';
import { Grid, Typography, Card } from '@material-ui/core';
import { offerCards } from './offerCardsData';
import { Link } from 'react-router-dom';
import AdStripe from '../../components/AdStripe/AdStripe';
import { ICard } from '../../@types/types';
import LogoStripe from '../../components/LogoStripe/LogoStripe';

interface FrontPageState {
    title: string;
}

class FrontPage extends Component<{}, FrontPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            title: 'Rengas Center',
        };
    }

    public render() {
        return (
            <Fragment>
                <section id="hero">
                    <Grid container={true} justifyContent="center">
                        <Grid item={true} xs={12}>
                            <Typography variant="h1">Rengas Center Klaukkala</Typography>
                        </Grid>
                    </Grid>
                </section>
                <AdStripe
                    url="/rengashotelli"
                    adText="sisältää säilytyksen, allevaihdon ja pesun (normaalisti 90€/kausi)"
                    adHeader="Rengashotellin tutustumistarjous uusille asiakkaille "
                    adPrice="70€/kausi"
                    buttonText="Lue lisää"
                />
                <LogoStripe />
                <section>
                    <img
                        style={{ width: '80%' }}
                        src="https://images-pethotelli.s3.eu-west-1.amazonaws.com/rengas-center/rengas-center-resurs-bank-kampanja.png"
                        alt="Resurs Bank RengasCenter-tili mainoskampanja"
                    />
                    <AdStripe
                        url="/rahoitus"
                        adText="Osta nyt, maksa vasta Elokuussa 2024. Laskun saatuasi voit valita sinulle sopivimman maksuvaihtoehdon."
                        adHeader="ResursBank, Osta nyt maksa vasta Elokuussa - "
                        adPrice="Kampanja-aika: 15.3.-15.5.2024"
                        buttonText="Lue lisää"
                    />
                </section>
                <section id="cardGrid" className="pb-2">
                    <Grid container={true}>
                        {offerCards.map((card: ICard) => {
                            return (
                                <Grid item={true} xs={6} md={3} key={card.id}>
                                    <Card className="card">
                                        <Link to={card.url}>
                                            <img src={card.image} alt={'Rengas Center Klaukkala' + card.title} />
                                            <Typography variant="h4">{card.title}</Typography>
                                        </Link>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </section>
            </Fragment>
        );
    }
}

export default FrontPage;
