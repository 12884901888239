import React, { FC } from 'react';
import { IFormField } from '../../@types/types';
import '../../assets/scss/Input.scss';

interface IInputProps {
    formField: IFormField;
    handleChange: (event: any) => void;
    value: string | number;
    labelText?: string;
    errorText?: string;
}

const Input: FC<IInputProps> = ({
    formField,
    handleChange,
    value,
    labelText,
    errorText,
}) => {
    const { id, name, label, type } = formField;
    return (
        <div className={`mb-3 wrapper`}>
            <label htmlFor={id} className="form-label">
                {labelText || label}
            </label>
            <input
                id={id}
                className={`form-control wrapper__input`}
                type={type}
                name={name}
                value={value}
                onInput={(event: any) => handleChange(event)}
            />
            {errorText && <p className="wrapper__errorText">{errorText}</p>}
        </div>
    );
};

export default Input;
