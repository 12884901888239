import React, { FC } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

interface ITyreHeaderProps {
    headerText: string;
}

const TyreHeader: FC<ITyreHeaderProps> = (props: ITyreHeaderProps) => {
    const { headerText } = props;
    return (
        <Grid container={true} justifyContent="flex-start" spacing={2}>
            <Box m={3}>
                <Typography variant="h4">{headerText}</Typography>
            </Box>
        </Grid>
    );
};

export default TyreHeader;
