import React, { Fragment, FC } from 'react';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface IAdStripeProps {
    adText: string;
    buttonText: string;
    adHeader: string;
    adPrice: string;
    url?: string;
    href?: string;
    offer?: boolean;
}

const AdStripe: FC<IAdStripeProps> = ({
    adHeader,
    adText,
    adPrice,
    buttonText,
    url,
    href,
    offer,
}) => {
    return (
        <Fragment>
            <hr />
            <aside>
                <Grid container={true} justifyContent="space-between">
                    <Grid item={true} xs={9} md={10}>
                        <Typography variant="h3">
                            {adHeader} <strong>{adPrice}</strong>
                        </Typography>
                        <Typography
                            className="colorAluminiumGray"
                            variant="body1"
                        >
                            {adText}
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={3} md={2}>
                        <Button variant="outlined" color="default">
                            {!!url && <Link to={url}>{buttonText}</Link>}
                            {!!href && (
                                <a href={href} target="__blank" className='yellow'>
                                    {buttonText}
                                </a>
                            )}
                        </Button>
                        <Box my={2} />
                        {offer && (
                            <Link className='yellow' to="/tarjouspyynto">Jätä tarjouspyyntö</Link>
                        )}
                    </Grid>
                </Grid>
            </aside>
            <hr />
        </Fragment>
    );
};

export default AdStripe;
